import React, { Fragment, useEffect, useState } from "react";
import GameBtn from "../components/GameBtn";
import JoinGame from "../components/JoinGame";
import CreateGame from "../components/CreateGame";
import { connect } from "react-redux";
import rotatingEarth from "../media/rotatingEarth.mp4";
import Roll from "react-reveal/Roll";
import * as gameActions from "../store/actions/game";
import * as sessionActions from "../store/actions/session";
import VideoBkg from "../components/VideoBkg";
import { useParams } from "react-router-dom";

const GameLobby = ({ games, getGames, resetSession }) => {
  const [isShowingLobbyContent, setIsShowingLobbyContent] = useState(false);
  const [isShowingLobby, setIsShowingLobby] = useState(true);
  const [isShowingCreateGame, setIsShowingCreateGame] = useState(false);
  const [isShowingJoinGame, setIsShowingJoinGame] = useState(false);
  const [urlGameCode, setUrlGameCode] = useState(false);
  const params = useParams("");

  useEffect(() => {
    if (params.gameCode !== null && params.gameCode !== undefined) {
      setIsShowingLobby(false);
      setIsShowingCreateGame(false);
      setIsShowingJoinGame(true);
      setUrlGameCode(params.gameCode);
    } else {
      setIsShowingLobby(true);
      setIsShowingCreateGame(false);
      setIsShowingJoinGame(false);
      setTimeout(() => setIsShowingLobbyContent(true), 1000);
    }
  }, [params]);

  useEffect(() => {
    if (games.length <= 0) {
      getGames();
    }
  }, [games, getGames]);

  const clickToCreateGame = () => {
    setIsShowingLobbyContent(false);
    setTimeout(() => {
      setIsShowingLobby(false);
      setIsShowingJoinGame(false);
      setIsShowingCreateGame(true);
    }, 500);
  };

  const clickToJoinGame = () => {
    setIsShowingLobbyContent(false);
    setTimeout(() => {
      setIsShowingLobby(false);
      setIsShowingCreateGame(false);
      setIsShowingJoinGame(true);
    }, 500);
  };

  const clickToLobby = () => {
    setIsShowingCreateGame(false);
    setIsShowingJoinGame(false);
    setIsShowingLobby(true);
    setTimeout(() => setIsShowingLobbyContent(true), 300);
  };
  return (
    <Fragment>
      <div className="gg-overlay-bkg" />
      <VideoBkg src={rotatingEarth} playbackRate={0.8} />
      {isShowingLobby && (
        <div className="gg-lobby-container">
          <Roll left when={isShowingLobby && isShowingLobbyContent}>
            <GameBtn
              className="gg-lobby-btn"
              onClick={() => clickToCreateGame}
              title="Create game"
            />
          </Roll>
          <Roll right when={isShowingLobby && isShowingLobbyContent}>
            <GameBtn
              className="gg-lobby-btn"
              onClick={() => clickToJoinGame}
              title="Join game"
            />
          </Roll>
        </div>
      )}

      <CreateGame
        isShowing={isShowingCreateGame}
        clickToLobby={() => clickToLobby}
      />
      <JoinGame
        isShowing={isShowingJoinGame}
        clickToLobby={() => clickToLobby}
        urlGameCode={urlGameCode}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    games: state.game.games,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetSession: () => dispatch(sessionActions.resetSession()),
    getGames: () => dispatch(gameActions.getGames()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GameLobby);

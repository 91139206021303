export default class UserRoundGuess {
  constructor(
    code,
    game,
    state,
    players,
    currentRound,
    created,
    expirationTime
  ) {
    this.code = code;
    this.game = game;
    this.state = state;
    this.players = players;
    this.currentRound = currentRound;
    this.created = created;
    this.expirationTime = expirationTime;
  }
}

import { useState, useEffect } from "react";

const Polyline = (options) => {
  const [polyline, setPolyline] = useState();

  useEffect(() => {
    if (!polyline) {
      setPolyline(new window.google.maps.Polyline());
    }

    // remove polyline from map on unmount
    return () => {
      if (polyline) {
        polyline.setMap(null);
      }
    };
  }, [polyline]);

  useEffect(() => {
    if (polyline) {
      polyline.setOptions(options);
    }
  }, [polyline, options]);

  return null;
};

export default Polyline;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//import { render } from "react-dom";
import { Provider } from "react-redux";
import sessionReducer from "./store/reducers/session";
import gameReducer from "./store/reducers/game";
import userReducer from "./store/reducers/user";

//import { configureStore, combineReducers, compose } from "@reduxjs/toolkit";
import { configureStore, compose, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import {
  createFirestoreInstance,
  firestoreReducer,
  getFirestore,
  reduxFirestore,
} from "redux-firestore";
import { ReactReduxFirebaseProvider, getFirebase } from "react-redux-firebase";
import firebase from "./config/fbConfigV8";
import { firebaseReducer } from "react-redux-firebase";

const rootReducer = combineReducers({
  game: gameReducer,
  session: sessionReducer,
  user: userReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
});

const store = configureStore({
  reducer: rootReducer,
  middleware: compose(
    (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        thunk.withExtraArgument({ getFirebase, getFirestore })
      ),
    reduxFirestore(firebase)
  ),
});

const rrfConfig = {
  //userProfile: "users",
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
  // enableClaims: true // Get custom claims along with the profile
};

const rrfProps = {
  firebase: firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <App />
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

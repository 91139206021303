import React from "react";
import gameStateType from "../model/gameStateTypes";
import {
  getPlayerRoundScore,
  getPlayerTotalScore,
} from "../functions/gameFunctions";

const PlayerCard = ({ player, currentRound, sessionState }) => {
  return (
    <div className="player-container">
      <h3 style={{ color: player.color }}>{player.name} </h3>
      <div className="score-container" style={{ background: player.color }}>
        <h4>
          Score: {getPlayerTotalScore(player)}{" "}
          {player.rounds !== undefined &&
            player.rounds.length > 1 &&
            sessionState === gameStateType.afterRound &&
            "+ " + getPlayerRoundScore(player, currentRound)}
        </h4>
      </div>
    </div>
  );
};

export default PlayerCard;

export const getPlayerTotalScore = (player) => {
  return player.rounds !== undefined
    ? player.rounds.reduce((scoreSum, round) => scoreSum + round.score, 0)
    : 0;
};

export const getPlayerRoundScore = (player, currentRound) => {
  let rounds = player.rounds.filter(
    (round) => round.roundNumber === currentRound
  );
  return rounds.length > 0 ? rounds[0].score : "";
};

export const getPlayerRoundGuessCoordinates = (player, currentRound) => {
  let rounds = player.rounds.filter(
    (round) => round.roundNumber === currentRound
  );

  return rounds.length > 0
    ? {
        lat: rounds[0].guessCoordinates._lat,
        lng: rounds[0].guessCoordinates._long,
      }
    : null;
};

export const getPlayerRoundGuessCountry = (player, currentRound) => {
  let rounds = player.rounds.filter(
    (round) => round.roundNumber === currentRound
  );

  return rounds.length > 0 ? rounds[0].guessCountry : null;
};

export const getPlayerRoundClosestCoordinates = (player, currentRound) => {
  let rounds = player.rounds.filter(
    (round) => round.roundNumber === currentRound
  );
  return rounds.length > 0 && rounds[0].roundCoordinates !== undefined
    ? {
        lat: rounds[0].roundCoordinates._lat,
        lng: rounds[0].roundCoordinates._long,
      }
    : null;
};

export const getPlayersSortedByTotalScore = (players) => {
  return players.sort((p1, p2) =>
    getPlayerTotalScore(p1) < getPlayerTotalScore(p2) ? -1 : 1
  );
};

import { GET_GAMES } from "../actions/game";

const initialState = {
  games: [],
};

const gameReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GAMES:
      return { ...state, games: action.payload };
    default:
      return state;
  }
};

export default gameReducer;

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const fbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "norbertlaszlo-com.firebaseapp.com",
  databaseURL:
    "https://norbertlaszlo-com-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "norbertlaszlo-com",
  storageBucket: "norbertlaszlo-com.appspot.com",
  messagingSenderId: "896560382176",
  appId: "1:896560382176:web:da04a2656ac6c13a5cd085",
  measurementId: "G-NKF2PDB1BR",
};

firebase.initializeApp(fbConfig);
firebase.firestore().settings({ timestampsInSnapshots: true, merge: true });

export default firebase;

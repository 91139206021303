import { JOIN_SESSION, RECONNECT, SET_CURRENT_GUESS } from "../actions/user";

const initialState = {
  player: {
    name: null,
    id: null,
  },
  session: {
    code: null,
    id: null,
  },
  currentGuess: {
    lat: null,
    lng: null,
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOIN_SESSION:
      return {
        ...state,
        player: action.payload.player,
        session: action.payload.session,
      };
    case RECONNECT:
      return {
        ...state,
        player: action.payload.player,
        session: action.payload.session,
      };
    case SET_CURRENT_GUESS:
      return {
        ...state,
        currentGuess: action.payload.guess,
      };
    default:
      return state;
  }
};
export default userReducer;

import React, { Fragment, useState, useEffect } from "react";
import PlayerCard from "../components/PlayerCard";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "@reduxjs/toolkit";
import Loading from "../components/Loading";
import useTimer from "../hooks/useTimer";
import * as sessionActions from "../store/actions/session";
import gameStateType from "../model/gameStateTypes";
import { useParams, useNavigate } from "react-router-dom";
import Rules from "../components/Rules";
import GameBtn from "../components/GameBtn";
import Map from "../components/map/Map";
import Marker from "../components/map/Marker";
import DounutMarker from "../components/map/DounutMarker";
import Polyline from "../components/map/Polyline";
import {
  getPlayerRoundScore,
  getPlayerRoundGuessCoordinates,
  getPlayerRoundClosestCoordinates,
  getPlayerRoundGuessCountry,
  getPlayersSortedByTotalScore,
} from "../functions/gameFunctions";

// TODOOOOOOO uninstall react-google-maps
const GameBoard = ({
  session,
  game,
  players,
  nextRound,
  startRound,
  endRound,
  endGame,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const svgMarkerStyle = {
    path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
    fillColor: "red",
    fillOpacity: 0.85,
    strokeWeight: 0,
    rotation: 8,
    scale: 2,
    anchor: new window.google.maps.Point(12.25, 22),
  };

  const [mapZoom, setMapZoom] = useState(
    game.mapZoom !== undefined ? game.mapZoom : 2
  );
  const [mapCenter, setMapCenter] = useState({
    lat: game.mapCenter !== undefined ? game.mapCenter._lat : 0,
    lng: game.mapCenter !== undefined ? game.mapCenter._long : 0,
  });
  const [isRoundImgLarge, setIsRoundImgLarge] = useState(true);
  const medalEmojis = ["🥇", "🥈", "🥉"];

  const [isBeforeRound, setIsBeforeRound] = useState(false);
  const [isAfterRound, setIsAfterRound] = useState(false);
  const [isDuringRound, setIsDuringRound] = useState(false);
  const [isLoadingAfterRound, setIsLoadingAfterRound] = useState(false);
  useEffect(() => {
    if (session.state === gameStateType.beforeRound) {
      setTimeout(() => setIsBeforeRound(true), 300);
    } else {
      setIsBeforeRound(false);
    }
    if (session.state === gameStateType.afterRound) {
      setTimeout(() => setIsAfterRound(true), 300);
    } else {
      setIsAfterRound(false);
    }
    if (session.state === gameStateType.duringRound) {
      setTimeout(() => setIsDuringRound(true), 300);
    } else {
      setIsDuringRound(false);
    }
  }, [session.state]);

  useEffect(() => {
    if ([null, undefined, ""].includes(params.sessionId)) {
      navigate(`/error`);
    }
    if (params.sessionId !== session.id) {
      navigate(`/reconnect/${params.sessionId}`);
    }
    if (session.state === gameStateType.gameOver) {
      navigate(`/gameover/${params.sessionId}`);
    }
  }, [params, session.id, session.state, navigate]);

  useEffect(() => {
    if (session.state === gameStateType.gameOver) {
      navigate(`/gameover/${params.sessionId}`);
    }
  }, [session.state, params, navigate]);

  const nextGameRound = () => {
    if (session.currentRound >= game.rounds.length) {
      endGame(session.id);
      navigate(`/gameover/${params.sessionId}`);
      return;
    }
    setIsAfterRound(false);
    setTimeout(() => {
      introCountdownTimer.reset();
      nextRound(session.id);
      introCountdownTimer.start(5);
    }, 1000);
  };

  const startGameRound = () => {
    setIsBeforeRound(false);
    setTimeout(() => {
      startRound(session.id);
      setTimeout(() => {
        roundCountdownTimer.reset();
        roundCountdownTimer.start(game.roundTime);
      }, 2000);
    }, 1500);
  };

  const endGameRound = () => {
    setIsLoadingAfterRound(true);
    setTimeout(() => {
      setIsLoadingAfterRound(false);
      endRound(session.id);
      setIsRoundImgLarge(false);
      setIsDuringRound(false);
    }, 5000);
  };

  const introCountdownTimer = useTimer({
    seconds: 5,
    running: false,
    tickLength: -1,
    onDone: startGameRound,
  });

  const roundCountdownTimer = useTimer({
    seconds: game.roundTime ? game.roundTime : 0,
    running: false,
    tickLength: -1,
    onDone: endGameRound,
  });

  const resetMap = () => {
    setMapZoom(game.mapZoom !== undefined ? game.mapZoom : 2);
    setMapCenter({
      lat: game.mapCenter !== undefined ? game.mapCenter._lat : 0,
      lng: game.mapCenter !== undefined ? game.mapCenter._long : 0,
    });
  };

  useEffect(() => {
    if (session.state === gameStateType.beforeRound) {
      introCountdownTimer.start(5);
    }
  }, [session.state]);

  useEffect(() => {
    if (document.getElementById("roundImageContainer") !== null) {
      if (isRoundImgLarge) {
        document
          .getElementById("roundImageContainer")
          .classList.add("round-image-container-large");
        document
          .getElementById("roundImageContainer")
          .classList.remove("round-image-container-small");
      } else {
        document
          .getElementById("roundImageContainer")
          .classList.add("round-image-container-small");
        document
          .getElementById("roundImageContainer")
          .classList.remove("round-image-container-large");
      }
    }
  }, [isRoundImgLarge]);

  useEffect(() => {
    let el = document.getElementById("beforeRoundLoading");
    if (el !== null) {
      el.style.opacity = isBeforeRound ? 0.95 : 0;
    }
  }, [isBeforeRound]);

  useEffect(() => {
    let el = document.getElementById("roundImageContainer");
    if (el !== null) {
      el.style.opacity = isDuringRound ? 1 : 0;
    }
  }, [isDuringRound]);

  useEffect(() => {
    let el = document.getElementById("topGuessContainer");
    let el2 = document.getElementById("gameActionBtn");
    let el3 = document.getElementById("roundImageContainer");
    if (el !== null) {
      el.style.bottom = isAfterRound ? "2rem" : "-10rem";
    }
    if (el2 !== null) {
      el2.style.opacity = isAfterRound ? 1 : "-10rem";
    }
    if (el3 !== null) {
      el3.style.opacity = isAfterRound ? 1 : 0;
    }
  }, [isAfterRound]);

  useEffect(() => {
    if (session.state === gameStateType.beforeRound) {
      resetMap();
    }
  }, [session.state]);

  return (
    <Fragment>
      <div className="game-board-container">
        <div className="info-wrapper">
          <div className="info-container">
            <div className="header">
              <h2>
                Round {session && Math.max(1, session.currentRound)}/
                {game.rounds && game.rounds.length}
              </h2>
            </div>
            {players.map((player) => {
              return (
                <PlayerCard
                  key={player.id}
                  player={player}
                  currentRound={session.currentRound}
                  sessionState={session.state}
                />
              );
            })}
          </div>
          <div className="info-footer">
            {[gameStateType.loadingGame, gameStateType.afterRound].includes(
              session.state
            ) && (
              <GameBtn
                id="gameActionBtn"
                onClick={() =>
                  session.currentRound === gameStateType.loadingGame
                    ? startGameRound
                    : nextGameRound
                }
                className="next-game"
                disabled={!game.rounds}
                title={
                  game.rounds &&
                  (session.state === gameStateType.loadingGame
                    ? "START GAME"
                    : session.currentRound >= game.rounds.length
                    ? "END GAME"
                    : "NEXT ROUND")
                }
              />
            )}
          </div>
        </div>
        <div className="game-container">
          <div className="game-round-timer card-shadow">
            {isLoadingAfterRound ? (
              <div className="rotating small-loading-eath">🌎</div>
            ) : (
              roundCountdownTimer.seconds
            )}
          </div>
          {[
            gameStateType.duringRound,
            gameStateType.beforeRound,
            gameStateType.loadingGame,
            gameStateType.afterRound,
          ].includes(session.state) && (
            <div>
              {session.state === gameStateType.loadingGame && (
                <Rules game={game}>
                  <div className="rules-start-btn-container">
                    <GameBtn onClick={() => nextGameRound} title="START GAME" />
                  </div>
                </Rules>
              )}
              {session.state === gameStateType.beforeRound && (
                <div id="beforeRoundLoading" className="loading-overlay">
                  <Loading
                    containerStyle="loading-overlay-container"
                    messageStyle="loading-overlay-message"
                    message={introCountdownTimer.seconds}
                  />
                </div>
              )}
              {[gameStateType.duringRound, gameStateType.afterRound].includes(
                session.state
              ) && (
                <div
                  id="roundImageContainer"
                  className="round-image-container round-image-container-large"
                  onClick={() => setIsRoundImgLarge((state) => !state)}
                >
                  <img
                    src={game.rounds[session.currentRound - 1].image}
                    alt="round item"
                  />
                  <div>
                    <h3>
                      {game.itemType}:{" "}
                      {gameStateType.afterRound === session.state &&
                      game.rounds[session.currentRound - 1].item != null &&
                      game.rounds[session.currentRound - 1].item
                        ? game.rounds[session.currentRound - 1].item
                        : "?"}
                    </h3>
                    <h3>
                      {game.locationType}:{" "}
                      {gameStateType.afterRound === session.state &&
                      game.rounds[session.currentRound - 1].location
                        ? game.rounds[session.currentRound - 1].location
                        : "?"}
                    </h3>
                  </div>
                </div>
              )}
              {session.state === gameStateType.afterRound && (
                <div
                  id="topGuessContainer"
                  className="top-guesses-container card-shadow"
                >
                  <ul>
                    {players !== undefined &&
                      getPlayersSortedByTotalScore(players)
                        .slice(0, 3)
                        .map((player, index) => {
                          return (
                            <li key={index}>
                              <label>{medalEmojis[index]}</label>
                              {player.name}
                              <span style={{ color: player.color }}>
                                +
                                {player.rounds !== undefined &&
                                  getPlayerRoundScore(
                                    player,
                                    session.currentRound
                                  )}
                              </span>
                            </li>
                          );
                        })}
                  </ul>
                </div>
              )}
              <div className="game-board-map-container">
                <Map
                  center={mapCenter}
                  onIdle={() => {}}
                  zoom={mapZoom}
                  style={{
                    flexGrow: "1",
                    height: "100%",
                  }}
                  map_id={game.mapId !== undefined ? "997e2df6df52e3f5" : null}
                  disableDefaultUI={true}
                  minZoom={2}
                  keyboardShortcuts={false}
                  clickableIcons={false}
                >
                  {session.state === gameStateType.afterRound &&
                    players.map((player) => {
                      //if (session.currentRound === player.guessList.length) {
                      let playerGuessCoordinates =
                        getPlayerRoundGuessCoordinates(
                          player,
                          session.currentRound
                        );
                      let playerClosestCoordinates =
                        getPlayerRoundClosestCoordinates(
                          player,
                          session.currentRound
                        );
                      let playerRoundCoordinate =
                        playerClosestCoordinates !== null
                          ? playerClosestCoordinates
                          : game.rounds[session.currentRound - 1].coordinates;
                      if (
                        playerGuessCoordinates !== null &&
                        playerRoundCoordinate !== null &&
                        getPlayerRoundGuessCountry(
                          player,
                          session.currentRound
                        ).toLowerCase() !==
                          game.rounds[
                            session.currentRound - 1
                          ].location.toLowerCase()
                      ) {
                        return (
                          <Polyline
                            key={player.id}
                            path={[
                              playerGuessCoordinates,
                              playerRoundCoordinate,
                            ]}
                            strokeColor={player.color}
                            strokeWeight={4}
                          />
                        );
                      }
                      //}
                      return null;
                    })}

                  {session.state === gameStateType.afterRound &&
                    players.map((player) => {
                      //if (session.currentRound === player.guessList.length) {
                      let playerGuessCoordinates =
                        getPlayerRoundGuessCoordinates(
                          player,
                          session.currentRound
                        );
                      if (playerGuessCoordinates !== null) {
                        return (
                          <Marker
                            key={player.id}
                            position={playerGuessCoordinates}
                            icon={{
                              ...svgMarkerStyle,
                              fillColor: player.color,
                            }}
                          />
                        );
                      }
                      //}
                      return null;
                    })}
                  {session.state === gameStateType.afterRound &&
                    !game.proximityByBorder && (
                      <DounutMarker
                        position={
                          game.rounds[session.currentRound - 1].coordinates
                        }
                      />
                    )}
                </Map>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  let players = [];
  let session = {};
  let game = {};
  if (
    state.firestore.data.geoGame_sessions !== undefined &&
    state.session.id !== null
  ) {
    let sessionPlayers =
      state.firestore.data.geoGame_sessions[state.session.id].players;
    if (state.firestore.data.geoGame_players !== undefined) {
      sessionPlayers.forEach((player) => {
        players.push({
          ...state.firestore.data.geoGame_players[player.id],
          id: player.id,
        });
      });
    }
    session = state.firestore.data.geoGame_sessions[state.session.id];
    if (session.game !== undefined && session.game !== "") {
      game = state.game.games.filter((game) => game.id === session.game)[0];
    }
  }
  return {
    session: { ...session, id: state.session.id },
    players: players,
    game: game,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nextRound: (sessionId) => dispatch(sessionActions.nextRound(sessionId)),
    startRound: (sessionId) => dispatch(sessionActions.startRound(sessionId)),
    endRound: (sessionId) => dispatch(sessionActions.endRound(sessionId)),
    endGame: (sessionId) => dispatch(sessionActions.endGame(sessionId)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    let arr = [];
    if (
      props.session.id !== "" &&
      props.session.id !== null &&
      props.session.id !== undefined
    ) {
      arr.push({ collection: "geoGame_sessions", doc: props.session.id });
      arr.push({
        collection: "geoGame_players",
        where: ["session", "==", props.session.id],
      });
    }
    return arr;
  })
)(GameBoard);

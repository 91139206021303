import React, { Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routing/AppRoutes";
import "./style/App.css";
import "./style/GameLobby.css";
import "./style/GameBoard.css";
import "./style/Game.css";
import "./style/GameOver.css";
import "./style/Reconnect.css";
import "./style/Error.css";
import { Wrapper } from "@googlemaps/react-wrapper";

function App() {
  return (
    <Fragment>
      <Wrapper
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        render={(status) => <h1>{status}</h1>}
      >
        <Router>
          <AppRoutes />
        </Router>
      </Wrapper>
    </Fragment>
  );
}

export default App;

import React from "react";
import rulesImg from "../media/rules.png";

const Rules = (props) => {
  return (
    <div className="rules-wrapper">
      <div className="rules-container card-shadow">
        <h1>Rules</h1>
        <p>Guess the origin of what you see...</p>
        <ul>
          <li>If you guess the correct region of origin you get 0 points.</li>
          <li>
            If you fail to guess the correct region of origin you get the
            distance to the correct origin as number points.
          </li>
          <img src={rulesImg} alt="game rules" />
          <li>
            Least points after{" "}
            {props.game.rounds ? props.game.rounds.length : "X"} rounds wins the
            game!
          </li>
        </ul>
        {props.children}
      </div>
    </div>
  );
};
export default Rules;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GameBtn from "../components/GameBtn";
import Spin from "react-reveal/Spin";
import astronautImg from "../media/astronaut.png";
import earthImg from "../media/earth.gif";
import VideoBkg from "../components/VideoBkg";
import starsVideo from "../media/stars.mp4";
const Error = () => {
  const navigate = useNavigate();
  const [angleValue, setAngleValue] = useState(1);

  const returnHome = () => {
    navigate(`/`);
  };

  useEffect(() => {
    setTimeout(() => {
      setAngleValue((angle) => angle + 0.0075);
    }, 10);
  }, [angleValue]);

  const [isWiggleTime, setWiggle] = useState(false);
  const hoverEffect = () => {
    if (!isWiggleTime) {
      setWiggle(true);
      setTimeout(() => {
        setWiggle(false);
      }, 1000);
    }
  };

  return (
    <div className="error-conatiner">
      <VideoBkg src={starsVideo} playbackRate={0.3} />
      <div className="error-header">
        <h1>Ops something went wrong...</h1>
        <h2>Are you lost?</h2>
      </div>
      <div className="error-content">
        <div
          className="earth no-highlight"
          onMouseEnter={() => hoverEffect()}
          onClick={() => hoverEffect()}
        >
          <Spin when={isWiggleTime}>
            <img src={earthImg} alt="earth" />
          </Spin>
          <div
            className="astronaut"
            style={{
              left: (Math.cos(angleValue) % 1) * 11 + "rem",
              top: (Math.sin(angleValue) % 1) * 11.5 + "rem",
            }}
          >
            <img
              src={astronautImg}
              style={{
                transform: `translate(-50%, -50%) rotate(${
                  angleValue * 200
                }deg)`,
              }}
              alt="astronaut"
            />
          </div>
        </div>
      </div>
      <div className="error-footer">
        <div>
          <GameBtn onClick={() => returnHome} title="RETURN TO EARTH" />
        </div>
      </div>
    </div>
  );
};

export default Error;

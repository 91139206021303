import { useState, useEffect, useCallback } from "react";

const interval =
  (delay = 0) =>
  /** @param {() => void} callback */ (callback) =>
    useEffect(() => {
      const id = setInterval(callback, delay);

      return () => clearInterval(id);
    }, [callback]);

const useTimer = ({
  seconds: initialSeconds = 0,
  running: initiallyRunning = false,
  tickLength: initialTickLength = 1,
  onDone: onDone = false,
} = {}) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const [running, setRunning] = useState(initiallyRunning);
  const tickLength =
    Math.max(Math.abs(initialTickLength), 0.001) * Math.sign(initialTickLength);
  const tick = useCallback(
    () =>
      running
        ? setSeconds((seconds) => {
            if (seconds > 0) {
              return seconds + tickLength;
            } else {
              setRunning(false);
              if (onDone) {
                onDone();
              }
              return 0;
            }
          })
        : undefined,
    [running]
  );

  const start = (seconds) => {
    seconds && seconds !== undefined && setSeconds(seconds);
    setRunning(true);
  };
  const pause = () => setRunning(false);
  const reset = () => setSeconds(0);
  const stop = () => {
    pause();
    reset();
  };

  const useInterval = interval(Math.abs(tickLength) * 1000);

  useInterval(tick);

  return { pause, reset, running, seconds, start, stop };
};
export default useTimer;

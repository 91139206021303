import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import GameBtn from "../components/GameBtn";
import { connect } from "react-redux";
import { LayoutContext } from "../routing/LayoutContext";
import VideoBkg from "../components/VideoBkg";
import * as gameActions from "../store/actions/game";
import * as sessionActions from "../store/actions/session";
import * as playerActions from "../store/actions/user";
import Spin from "react-reveal/Spin";
import sateliteImg from "../media/satelite3.png";
import earthImg from "../media/earth.gif";
import starsVideo from "../media/stars.mp4";
const Reconnect = ({ reconnectSession, getGames, reconnectUser }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { dispatch } = useContext(LayoutContext);
  const [angleValue, setAngleValue] = useState(1);

  const reconnect = () => {
    if ([null, undefined, ""].includes(params.sessionId)) {
      navigate(`/error`);
    } else {
      getGames()
        .then()
        .catch((err) => {
          dispatch({
            type: "PUSH_ALERT",
            payload: {
              message: err.message,
              timeoutLength: 5000,
              type: "error",
            },
          });
        });

      if ([null, undefined, ""].includes(params.userId)) {
        // reconnect game board
        reconnectSession(params.sessionId)
          .then((sessionId) => {
            if (sessionId !== null) {
              dispatch({
                type: "PUSH_ALERT",
                payload: {
                  message: "Rejoining session...",
                  timeoutLength: 5000,
                  type: "success",
                },
              });
              setTimeout(() => navigate(`/gameboard/${sessionId}`), 3000);
            } else {
              throw Error("Could not join session");
            }
          })
          .catch((err) => {
            dispatch({
              type: "PUSH_ALERT",
              payload: {
                message: err.message,
                timeoutLength: 5000,
                type: "error",
              },
            });
            return;
          });
      } else {
        reconnectUser(params.sessionId, params.userId)
          .then((res) => {
            if (res !== null) {
              dispatch({
                type: "PUSH_ALERT",
                payload: {
                  message: "Rejoining session...",
                  timeoutLength: 5000,
                  type: "success",
                },
              });
              setTimeout(
                () => navigate(`/game/${res.sessionId}/${res.userId}`),
                3000
              );
            } else {
              throw Error("Could not join session");
            }
          })
          .catch((err) => {
            dispatch({
              type: "PUSH_ALERT",
              payload: {
                message: err.message,
                timeoutLength: 5000,
                type: "error",
              },
            });
          });
      }
    }
  };

  const returnHome = () => {
    navigate(`/`);
  };

  useEffect(() => {
    setTimeout(() => {
      setAngleValue((angle) => angle + 0.0075);
    }, 10);
  }, [angleValue]);

  const [isWiggleTime, setWiggle] = useState(false);
  const hoverEffect = () => {
    if (!isWiggleTime) {
      setWiggle(true);
      setTimeout(() => {
        setWiggle(false);
      }, 1000);
    }
  };

  return (
    <div className="reconnect-conatiner">
      <VideoBkg src={starsVideo} playbackRate={0.3} />
      <div className="reconnect-header">
        <h1>Lost connection to earth...</h1>
        <h2>Try to reconnect!</h2>
      </div>
      <div className="reconnect-content">
        <div
          className="earth no-highlight"
          onMouseEnter={() => hoverEffect()}
          onClick={() => hoverEffect()}
        >
          <Spin when={isWiggleTime}>
            <img src={earthImg} alt="earth" />
          </Spin>
          <div
            className="satelite"
            style={{
              left: (Math.cos(angleValue) % 1) * 10 + "rem",
              top: (Math.sin(angleValue) % 1) * 10.5 + "rem",
            }}
          >
            <img
              src={sateliteImg}
              style={{
                transform: `translate(-50%, -50%) rotate(${
                  angleValue * 150
                }deg)`,
              }}
              alt="satelite"
            />
          </div>
        </div>
      </div>
      <div className="reconnect-footer">
        <div>
          <GameBtn onClick={() => reconnect} title="RECONNECT" />
          <GameBtn onClick={() => returnHome} title="RETURN TO EARTH" />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    reconnectSession: (sessionId) =>
      dispatch(sessionActions.reconnect(sessionId)),
    getGames: () => dispatch(gameActions.getGames()),
    reconnectUser: (sessionId, userId) =>
      dispatch(playerActions.reconnect(sessionId, userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reconnect);

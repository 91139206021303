// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
/*import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDocs,
  initializeFirestore,
  Timestamp
} from "firebase/firestore";*/
import * as fs from "firebase/firestore";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "norbertlaszlo-com.firebaseapp.com",
  databaseURL:
    "https://norbertlaszlo-com-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "norbertlaszlo-com",
  storageBucket: "norbertlaszlo-com.appspot.com",
  messagingSenderId: "896560382176",
  appId: "1:896560382176:web:da04a2656ac6c13a5cd085",
  measurementId: "G-NKF2PDB1BR",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object

// Initialize Cloud Firestore and get a reference to the service
//const db = getFirestore(app, { timestampsInSnapshots: true });
const db = fs.initializeFirestore(app, {
  //experimentalForceLongPolling: true, // this line
  //useFetchStreams: false, // and this line
  timestampsInSnapshots: true,
});
export { db };

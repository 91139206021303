export default class UserRoundGuess {
  constructor(
    roundNumber,
    score,
    guessCoordinates,
    roundCoordinates,
    guessCountry
  ) {
    this.roundNumber = roundNumber;
    this.score = score;
    this.guessCoordinates = guessCoordinates;
    this.roundCoordinates = roundCoordinates;
    this.guessCountry = guessCountry;
  }
}

import { useState, useEffect } from "react";

const DounutMarker = (options) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    const svgCircleStyle = {
      path: "M12 12m-6,10a6,6,0 1,0 12,0a 6,6 0 1,0 -12,0zM12 12m-3,10a3,3,0 0,1 6,0a 3,3 0 0,1 -6,0z",
      fillColor: "red",
      fillOpacity: 0.6,
      strokeWeight: 0,
      scale: 1.2,
      anchor: new window.google.maps.Point(12.25, 22),
    };
    if (marker) {
      marker.setOptions({ ...options, icon: svgCircleStyle });
    }
  }, [marker, options]);

  return null;
};

export default DounutMarker;

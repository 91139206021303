export const getCountryOfCoordinates = async (lat, lng) => {
  const response = await fetch(
    "https://maps.googleapis.com/maps/api/geocode/json" +
      `?latlng=${String(lat)},${String(lng)}` +
      //"&location_type=ROOFTOP" +
      "&result_type=country" +
      `&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
  );
  const myJson = await response.json();

  // remove all results not containing country in address_component
  let tmp = {
    results: myJson.results.filter((res) => {
      let a = false;
      res.address_components.forEach((component) => {
        if (component.types.includes("country") && !a) {
          a = true;
        }
      });
      return a;
    }),
  };
  // remove all address components not containing country as type
  tmp = {
    results: tmp.results.map((res) => {
      res = {
        address_components: res.address_components.filter((comp) =>
          comp.types.includes("country")
        ),
      };
      return res;
    }),
  };

  let foundCountries = [];
  // extract country from all adress components in all results
  tmp.results.forEach((res) =>
    res.address_components.forEach((comp) => {
      if (
        comp.long_name !== undefined &&
        typeof comp.long_name === "string" &&
        comp.long_name.length > 0
      ) {
        foundCountries.push(comp.long_name.toLowerCase());
      }
    })
  );

  // perform majority vote to determine country
  let uniqueCountriesCount = {};
  foundCountries.forEach((country) => {
    if (country in uniqueCountriesCount) {
      uniqueCountriesCount[country] += 1;
    } else {
      uniqueCountriesCount[country] = 1;
    }
  });
  let mostFrequent = 0;
  let majorityCountry = "";
  Object.entries(uniqueCountriesCount).forEach((entry) => {
    let country = entry[0];
    let count = entry[1];
    if (count > mostFrequent) {
      majorityCountry = country;
    }
  });
  return majorityCountry;
};

export const computeDistance = (coord1, coord2) => {
  const R = 6371; //6371e3;
  const p1 = (coord1.lat * Math.PI) / 180;
  const p2 = (coord2.lat * Math.PI) / 180;
  const deltaLon = coord2.lng - coord2.lng;
  const deltaLambda = (deltaLon * Math.PI) / 180;
  const d =
    Math.acos(
      Math.sin(p1) * Math.sin(p2) +
        Math.cos(p1) * Math.cos(p2) * Math.cos(deltaLambda)
    ) * R;
  return d;
};

import React, { useState } from "react";

const Test = () => {
  const [res, setRes] = useState({});
  const [lat, setLat] = useState(57.6820091);
  const [lng, setLng] = useState(11.9839082);
  const [country, setCountry] = useState("");
  const getCountryOfCoordinates = async () => {
    const response = await fetch(
      "https://maps.googleapis.com/maps/api/geocode/json" +
        `?latlng=${String(lat)},${String(lng)}` +
        //"&location_type=ROOFTOP" +
        "&result_type=country" +
        "&key=AIzaSyApC3goEc8FBlzqTtu3xKECMRjNAsCpNSk"
    );
    const myJson = await response.json();

    // remove all results not containing country in address_component
    let tmp = {
      results: myJson.results.filter((res) => {
        let a = false;
        res.address_components.forEach((component) => {
          if (component.types.includes("country") && !a) {
            a = true;
          }
        });
        return a;
      }),
    };
    // remove all address components not containing country as type
    tmp = {
      results: tmp.results.map((res) => {
        res = {
          address_components: res.address_components.filter((comp) =>
            comp.types.includes("country")
          ),
        };
        return res;
      }),
    };

    let foundCountries = [];
    // extract country from all adress components in all results
    tmp.results.forEach((res) =>
      res.address_components.forEach((comp) => {
        if (
          comp.long_name !== undefined &&
          typeof comp.long_name === "string" &&
          comp.long_name.length > 0
        ) {
          foundCountries.push(comp.long_name.toLowerCase());
        }
      })
    );

    // perform majority vote to determine country
    let uniqueCountriesCount = {};
    foundCountries.forEach((country) => {
      if (country in uniqueCountriesCount) {
        uniqueCountriesCount[country] += 1;
      } else {
        uniqueCountriesCount[country] = 1;
      }
    });
    let mostFrequent = 0;
    let majorityCountry = "";
    Object.entries(uniqueCountriesCount).forEach((entry) => {
      let country = entry[0];
      let count = entry[1];
      if (count > mostFrequent) {
        majorityCountry = country;
      }
    });
    setCountry(majorityCountry);

    setRes(myJson);
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        display: "relative",
        height: "100vh",
        width: "100vw",
      }}
    >
      <button onClick={() => getCountryOfCoordinates()}>get country</button>
      <input
        type="text"
        onChange={(e) => setLat(e.target.value)}
        placeholder="Latitude"
        value={lat}
      />
      <input
        type="text"
        onChange={(e) => setLng(e.target.value)}
        placeholder="Longitude"
        value={lng}
      />
      <br />
      <h2>Country: {country !== undefined ? country : "none"}</h2>
      <pre id="json">{JSON.stringify(res, undefined, 2)}</pre>
    </div>
  );
};

export default Test;

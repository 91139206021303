import React, { useState } from "react";
import Swing from "react-reveal/Swing";

const GameBtn = (props) => {
  const [isWiggleTime, setWiggle] = useState(false);
  const hoverEffect = () => {
    if (!isWiggleTime) {
      setWiggle(true);
      setTimeout(() => {
        setWiggle(false);
      }, 1000);
    }
  };
  return (
    <Swing when={isWiggleTime}>
      <button
        {...props}
        className={"gg-main-btn " + props.className}
        onMouseEnter={() => hoverEffect()}
        onClick={props.onClick()}
      >
        {props.title}
      </button>
    </Swing>
  );
};

export default GameBtn;

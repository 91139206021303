import React, { Fragment, useEffect, useState, useContext } from "react";
import GameBtn from "../components/GameBtn";
import { LayoutContext } from "../routing/LayoutContext";
import Fade from "react-reveal/Fade";
import { connect } from "react-redux";
import * as playerActions from "../store/actions/user";
import { useNavigate } from "react-router-dom";

const JoinGame = (props) => {
  const { dispatch } = useContext(LayoutContext);
  const [isShowingContent, setIsShowingContent] = useState(false);
  const [gameCode, setGameCode] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (props.isShowing) {
      setIsShowingContent(true);
    }
  }, [props.isShowing]);

  useEffect(() => {
    if (props.urlGameCode.length > 0 && props.urlGameCode !== null) {
      setGameCode(props.urlGameCode);
    }
  }, [props.urlGameCode]);

  const toLobby = () => {
    setIsShowingContent(false);
    setTimeout(props.clickToLobby(), 500);
  };

  const joinSession = () => {
    if (name.length > 0 && gameCode.length > 0) {
      props
        .joinSession(gameCode, name)
        .then((res) => {
          if (res.sessionId !== null && res.userId) {
            setName("");
            setGameCode("");
            navigate(`/game/${res.sessionId}/${res.userId}`);
          } else {
            throw Error("Could not join session");
          }
        })
        .catch((err) => {
          dispatch({
            type: "PUSH_ALERT",
            payload: {
              message: err.message,
              timeoutLength: 5000,
              type: "error",
            },
          });
        });
    } else {
      dispatch({
        type: "PUSH_ALERT",
        payload: {
          message: "Enter name and code",
          timeoutLength: 5000,
          type: "warning",
        },
      });
    }
  };

  return (
    <Fragment>
      {props.isShowing && (
        <div className="gg-join-game-container">
          <Fade bottom when={props.isShowing && isShowingContent}>
            <div className="gg-join-game-wrapper">
              <form>
                <label>Name</label>
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter name"
                />
                <label>Game code</label>
                <input
                  type="text"
                  onChange={(e) => setGameCode(e.target.value)}
                  value={gameCode}
                  placeholder="Enter game code"
                />
              </form>
              <GameBtn
                title="OK"
                className="gg-ok-join-game"
                onClick={() => joinSession}
              />
              <br />
              <GameBtn
                title="Back to lobby"
                className="gg-to-lobby"
                onClick={() => toLobby}
              />
            </div>
          </Fade>
        </div>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return { sessionId: state.user.session.id };
};
const mapDispatchToProps = (dispatch) => {
  return {
    joinSession: (code, name) =>
      dispatch(playerActions.joinSession(code, name)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinGame);

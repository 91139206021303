import React, { useEffect, useState } from "react";
import earthImg from "../media/earth.gif";
import Pulse from "react-reveal/Pulse";
import VideoBkg from "./VideoBkg";
import starsVideo from "../media/stars.mp4";
const Loading = (props) => {
  const [isWiggleTime, setWiggle] = useState(false);

  useEffect(() => {
    setWiggle(true);
    setTimeout(() => {
      setWiggle(false);
    }, 2500);
  }, [isWiggleTime]);

  return (
    <div className={`loading-container ${props.containerStyle}`}>
      {props.videoBkg && <VideoBkg src={starsVideo} playbackRate={0.3} />}

      <label className={`no-highlight loading-message ${props.messageStyle}`}>
        {props.message}
      </label>
      <div className="loading-icon-wrapper">
        <div className="loading-icon-container">
          <Pulse when={isWiggleTime}>
            <img
              id="loadingIcon"
              style={{ fontSize: "4rem" }}
              className={`loading-icon no-highlight ${props.iconStyle}`}
              src={earthImg}
              alt="loading"
            />
          </Pulse>
        </div>
      </div>
    </div>
  );
};

export default Loading;

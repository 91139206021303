export default class OnlineUser {
  constructor(name, session, color, rounds, created, expirationTime) {
    this.name = name;
    this.session = session;
    this.color = color;
    this.rounds = rounds;
    this.created = created;
    this.expirationTime = expirationTime;
  }
}

import * as fs from "firebase/firestore";
import { db } from "../../config/fbConfigV9";

export const GET_GAMES = "GET_GAMES";

export const getGames = () => async (dispatch) => {
  try {
    const querySnapshot = await fs.getDocs(
      fs.collection(db, "geoGame_games") //.withConverter(GameConverter)
    );

    let games = querySnapshot.docs.map((game) => ({
      ...game.data(),
      rounds: game.data().rounds.map((round) => {
        return {
          ...round,
          coordinates: {
            lat: round.coordinates._lat,
            lng: round.coordinates._long,
          },
        };
      }),
      id: game.id,
    }));

    games = games.filter((game) => game.active === true);

    dispatch({
      type: GET_GAMES,
      payload: games,
    });
  } catch (err) {
    console.error(err.message);
  }
};

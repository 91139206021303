const gameStateType = {
  inLobby: "IN_LOBBY",
  loadingGame: "LOADING_GAME",
  beforeRound: "BEFORE_ROUND",
  duringRound: "DURING_ROUND",
  afterRound: "AFTER_ROUND",
  gameOver: "GAME_OVER",
};

export default gameStateType;

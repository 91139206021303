import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "@reduxjs/toolkit";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import useTimer from "../hooks/useTimer";
import gameStateType from "../model/gameStateTypes";
import * as gameActions from "../store/actions/game";
import * as playerActions from "../store/actions/user";
import Map from "../components/map/Map";
import Marker from "../components/map/Marker";
import DounutMarker from "../components/map/DounutMarker";
import Rules from "../components/Rules";
import Polyline from "../components/map/Polyline";

import {
  getPlayerRoundScore,
  getPlayerRoundGuessCoordinates,
  getPlayerRoundClosestCoordinates,
  getPlayerRoundGuessCountry,
} from "../functions/gameFunctions";

const Game = ({
  user,
  session,
  player,
  game,
  makeGuessAction,
  getGames,
  guessCoordinates,
  playerRoundCoordinates,
  saveCurrentGuess,
}) => {
  const svgMarkerStyle = {
    path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
    fillColor: "red",
    fillOpacity: 0.85,
    strokeWeight: 0,
    rotation: 9,
    scale: 2,
    anchor: new window.google.maps.Point(12.25, 22),
  };
  const [guess, setGuess] = useState({
    lat: game.mapCenter !== undefined ? game.mapCenter._lat : 0,
    lng: game.mapCenter !== undefined ? game.mapCenter._long : 0,
  });
  const [mapZoom, setMapZoom] = useState(
    game.mapZoom !== undefined ? game.mapZoom : 2
  );
  const [mapCenter, setMapCenter] = useState({
    lat: game.mapCenter !== undefined ? game.mapCenter._lat : 0,
    lng: game.mapCenter !== undefined ? game.mapCenter._long : 0,
  });
  const params = useParams();
  const navigate = useNavigate();
  const [markerStyle, setMarkerStyle] = useState(svgMarkerStyle);
  const [isAfterRound, setIsAfterRound] = useState(false);
  const [isLoadingAfterRound, setIsLoadingAfterRound] = useState(false);

  useEffect(() => {
    if (session.state === gameStateType.afterRound) {
      setTimeout(() => setIsAfterRound(true), 300);
    } else {
      setIsAfterRound(false);
    }
  }, [session.state]);

  useEffect(() => {
    setMarkerStyle((obj) => {
      return { ...obj, fillColor: player.color };
    });
  }, [player.color]);

  useEffect(() => {
    if (session.state === gameStateType.beforeRound) {
      resetMap();
    }
  }, [session.state]);

  useEffect(() => {
    if (
      [null, undefined, ""].includes(params.sessionId) ||
      [null, undefined, ""].includes(params.userId)
    ) {
      navigate(`/error`);
    }
    if (
      params.sessionId !== user.session.id ||
      params.userId !== user.player.id
    ) {
      navigate(`/reconnect/${params.sessionId}/${params.userId}`);
    }
  }, [params, user.session.id, user.player.id, navigate]);

  useEffect(() => {
    if (session.state === gameStateType.gameOver) {
      navigate(`/gameover/${params.sessionId}/${params.userId}`);
    }
  }, [params, session.state, navigate]);

  useEffect(() => {
    if (session.state !== gameStateType.duringRound) {
      setIsLoadingAfterRound(false);
    }
  }, [session.state]);

  const makeFinalGuess = () => {
    pause();
    setIsLoadingAfterRound(true);
    let round = game.rounds[session.currentRound - 1];
    makeGuessAction(
      guess,
      round.coordinates,
      round.location,
      session.currentRound,
      game.proximityByBorder,
      player.id
    );
  };

  const {
    pause,
    //reset, running,
    seconds,
    start,
    //stop
  } = useTimer({
    seconds: game.roundTime ? game.roundTime : 0,
    running: false,
    tickLength: -0.01,
    onDone: () => makeFinalGuess(),
    dependenciesArr: [guess],
  });

  useEffect(() => {
    if (game === undefined) {
      getGames();
    }
  }, [game, getGames]);

  useEffect(() => {
    if (session.state === gameStateType.duringRound) {
      start(game.roundTime);
    }
  }, [session.state]);

  const onIdle = (m) => {
    setMapZoom(m.getZoom());
    let center = m.getCenter().toJSON();
    if (Math.abs(center.lat) > 88) {
      center.lat = 0;
      m.setCenter(center);
    }
    setMapCenter(center);
  };

  const resetMap = () => {
    setMapZoom(game.mapZoom !== undefined ? game.mapZoom : 2);
    setMapCenter({
      lat: game.mapCenter !== undefined ? game.mapCenter._lat : 0,
      lng: game.mapCenter !== undefined ? game.mapCenter._long : 0,
    });
    setGuess({
      lat: game.mapCenter !== undefined ? game.mapCenter._lat : 0,
      lng: game.mapCenter !== undefined ? game.mapCenter._long : 0,
    });
    saveCurrentGuess({
      lat: game.mapCenter !== undefined ? game.mapCenter._lat : 0,
      lng: game.mapCenter !== undefined ? game.mapCenter._long : 0,
    });
  };

  useEffect(() => {
    let el = document.getElementById("roundScoreContain");
    if (el !== null) {
      el.style.bottom = isAfterRound ? "5%" : "-15%";
    }
  }, [isAfterRound]);

  return (
    <div className="user-game-container">
      {session.state === gameStateType.inLobby ? (
        <Loading
          videoBkg={true}
          containerStyle="loading-game-starting-container"
          messageStyle="loading-game-starting-msg"
          message={"Waiting for game to start"}
        />
      ) : (
        <div>
          {[
            gameStateType.duringRound,
            gameStateType.beforeRound,
            gameStateType.loadingGame,
            gameStateType.afterRound,
          ].includes(session.state) && (
            <div className="game-map-container">
              {gameStateType.loadingGame === session.state && (
                <Rules game={game}></Rules>
              )}
              <Map
                center={mapCenter}
                onClick={(e) => {
                  if (session.state === gameStateType.duringRound) {
                    saveCurrentGuess(e.latLng.toJSON());
                    setGuess(e.latLng.toJSON());
                  }
                }}
                onIdle={onIdle}
                zoom={mapZoom}
                style={{
                  flexGrow: "1",
                  height: "100%",
                }}
                map_id={game.mapId !== undefined ? "997e2df6df52e3f5" : null}
                disableDefaultUI={true}
                minZoom={2}
                keyboardShortcuts={false}
                clickableIcons={false}
              >
                {guess.lat !== 0 &&
                  guess.lng !== 0 &&
                  session.state === gameStateType.duringRound && (
                    <Marker position={guess} icon={markerStyle} />
                  )}
                {session.state === gameStateType.afterRound &&
                  guessCoordinates !== null && (
                    <Marker position={guessCoordinates} icon={markerStyle} />
                  )}
                {session.state === gameStateType.afterRound &&
                  guessCoordinates !== null &&
                  playerRoundCoordinates !== null &&
                  getPlayerRoundGuessCountry(
                    player,
                    session.currentRound
                  ).toLowerCase() !==
                    game.rounds[
                      session.currentRound - 1
                    ].location.toLowerCase() && (
                    <Polyline
                      key={player.id}
                      path={[guessCoordinates, playerRoundCoordinates]}
                      strokeColor={player.color}
                      strokeWeight={4}
                    />
                  )}
                {session.state === gameStateType.afterRound &&
                  guessCoordinates !== null &&
                  !game.proximityByBorder && (
                    <DounutMarker
                      position={
                        game.rounds[session.currentRound - 1].coordinates
                      }
                    />
                  )}
                {session.state === gameStateType.afterRound &&
                  guessCoordinates !== null && (
                    <div
                      id="roundScoreContain"
                      className="user-round-score-container card-shadow"
                    >
                      <h2>
                        Round score:{" "}
                        {getPlayerRoundScore(player, session.currentRound)}
                      </h2>
                    </div>
                  )}
              </Map>
            </div>
          )}
          <h2 className="user-round-info card-shadow">
            {session &&
              game.rounds &&
              "Round " +
                Math.max(1, session.currentRound) +
                "/" +
                game.rounds.length}
          </h2>
          <h2
            className={`user-time-info card-shadow ${
              isLoadingAfterRound && "user-time-info-loading"
            }`}
          >
            {isLoadingAfterRound ? (
              <div className="rotating small-loading-eath">🌎</div>
            ) : game.roundTime ? (
              <b>
                {Math.round(seconds).toString()}
                <span>
                  {seconds === game.roundTime
                    ? "00"
                    : (seconds - Math.floor(seconds))
                        .toString()
                        .substring(2, 4)}
                </span>
              </b>
            ) : (
              "-"
            )}
          </h2>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  let game = {};
  let player = {};
  let session = {};
  let guessCoordinates = null;
  let playerRoundCoordinates = null;
  if (
    state.firestore.data.geoGame_sessions !== undefined &&
    state.user.session.id !== null
  ) {
    if (
      state.firestore.data.geoGame_sessions[state.user.session.id] !== undefined
    ) {
      session = state.firestore.data.geoGame_sessions[state.user.session.id];
      if (session.game !== undefined && session.game !== "") {
        game = state.game.games.filter((game) => game.id === session.game)[0];
      }
      if (
        state.user.player.id !== undefined &&
        state.user.player.id !== "" &&
        state.user.player.id !== null
      ) {
        player = {
          ...state.firestore.data.geoGame_players[state.user.player.id],
          id: state.user.player.id,
        };
        if (session.state === gameStateType.afterRound) {
          let playerClosestCoordinates = getPlayerRoundClosestCoordinates(
            player,
            session.currentRound
          );
          playerRoundCoordinates =
            playerClosestCoordinates !== null
              ? playerClosestCoordinates
              : game.rounds[session.currentRound - 1].coordinates;
          guessCoordinates = getPlayerRoundGuessCoordinates(
            player,
            session.currentRound
          );
        }
      }
    }
  }

  return {
    user: state.user,
    session: session,
    player: player,
    game: game,
    guessCoordinates: guessCoordinates,
    playerRoundCoordinates: playerRoundCoordinates,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    makeGuessAction: (
      guessCoordinates,
      actualCoordinate,
      actualCountry,
      roundNumber,
      proximityByBorder,
      playerId
    ) =>
      dispatch(
        playerActions.makeGuess(
          guessCoordinates,
          actualCoordinate,
          actualCountry,
          roundNumber,
          proximityByBorder,
          playerId
        )
      ),
    getGames: () => dispatch(gameActions.getGames()),
    saveCurrentGuess: (coordinates) =>
      dispatch(playerActions.setCurrentGuess(coordinates)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    let arr = [];
    if (props.sessionId !== "" && props.sessionId !== null) {
      arr.push({ collection: "geoGame_sessions", doc: props.sessionId });
    }
    if (props.user.player.id !== "" && props.user.player.id !== null) {
      arr.push({ collection: "geoGame_players", doc: props.user.player.id });
    }
    return arr;
  })
)(Game);

import { SET_SESSION, START_GAME, RESET_SESSION } from "../actions/session";

const initialState = {
  code: "",
  gameId: "",
  players: [],
  id: null,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION:
      return {
        ...state,
        code: action.payload.code,
        id: action.payload.id,
      };
    case START_GAME:
      return {
        ...state,
        players: action.payload.players,
        gameId: action.payload.gameId,
      };
    case RESET_SESSION:
      return initialState;
    default:
      return state;
  }
};
export default sessionReducer;

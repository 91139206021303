import React, { Fragment, useEffect, useState, useContext } from "react";
import GameBtn from "../components/GameBtn";
import Fade from "react-reveal/Fade";
import { LayoutContext } from "../routing/LayoutContext";
import { connect } from "react-redux";
import * as sessionActions from "../store/actions/session";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import gameStateType from "../model/gameStateTypes";
import { QRCodeSVG } from "qrcode.react";

const CreateGame = ({
  createSession,
  sessionCode,
  isShowing,
  sessionId,
  clickToLobby,
  postStartGame,
  players,
  sessionState,
  games,
}) => {
  const [isShowingContent, setIsShowingContent] = useState(false);
  const [chosenGameId, setChosenGameId] = useState(null);
  const { dispatch } = useContext(LayoutContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isShowingContent) {
      if (
        sessionCode === "" ||
        (sessionState !== null &&
          sessionState !== undefined &&
          sessionState !== gameStateType.inLobby)
      ) {
        createSession()
          .then(() => {})
          .catch((err) => {
            dispatch({
              type: "PUSH_ALERT",
              payload: {
                message: err.message,
                timeoutLength: 5000,
                type: "error",
              },
            });
          });
      }
    }
  }, [isShowingContent, dispatch, createSession, sessionCode, sessionState]);

  useEffect(() => {
    if (isShowing) {
      setIsShowingContent(true);
    }
  }, [isShowing]);

  const toLobby = () => {
    setIsShowingContent(false);
    setTimeout(clickToLobby(), 500);
  };

  const startGame = () => {
    if (players.length > 0) {
      if (chosenGameId !== null) {
        postStartGame(sessionId, chosenGameId, players);
        navigate("/gameboard/" + sessionId);
      } else {
        dispatch({
          type: "PUSH_ALERT",
          payload: {
            message: "Select a game to play",
            timeoutLength: 5000,
            type: "warning",
          },
        });
      }
    } else {
      dispatch({
        type: "PUSH_ALERT",
        payload: {
          message: "Not enough players",
          timeoutLength: 5000,
          type: "warning",
        },
      });
    }
  };

  return (
    <Fragment>
      {isShowing && (
        <div className="gg-create-game-container">
          <Fade bottom when={isShowing && isShowingContent}>
            <div className="gg-create-game-left-panel">
              <div className="gg-create-game-left-panel-top">
                <h2>Enter code to join</h2>
                <h3 className="gg-game-code">{sessionCode}</h3>
                <QRCodeSVG
                  className="qr-code"
                  value={`https://geogame.norbertlaszlo.com/join/${sessionCode}`}
                />
              </div>
              <div className="gg-create-game-left-panel-mid">
                <h2>Players joined</h2>
                <ul>
                  {players.map((player) => {
                    return <li key={player.id}>{player.name}</li>;
                  })}
                </ul>
              </div>
              <div className="gg-create-game-left-panel-bottom">
                <GameBtn
                  title="Start"
                  className="gg-start-btn"
                  onClick={() => startGame}
                />
                <GameBtn
                  title="Back to lobby"
                  className="gg-to-lobby-btn"
                  onClick={() => toLobby}
                />
              </div>
            </div>
          </Fade>
          <Fade top when={isShowing && isShowingContent}>
            <div className="gg-create-game-right-panel">
              <h2>Select game</h2>
              <div className="gg-create-game-right-panel-body">
                {games.length > 0 ? (
                  games.map((game) => {
                    return (
                      <div
                        id={game.id}
                        key={game.id}
                        className={`gg-game-list-item card-shadow ${
                          game.id === chosenGameId &&
                          "gg-game-list-item-selected"
                        }`}
                        onClick={() => {
                          setChosenGameId(game.id);
                        }}
                      >
                        <h3 className="game-title">{game.title}</h3>
                        <h4 className="game-rounds">
                          Rounds: {game.rounds.length}
                        </h4>
                        <p className="game-description">{game.description}</p>
                      </div>
                    );
                  })
                ) : (
                  <h4 className="err-none-found">No games found...</h4>
                )}
              </div>
            </div>
          </Fade>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  let players = [];
  let sessionState = null;
  if (
    state.firestore.data.geoGame_sessions !== undefined &&
    state.session.id !== null
  ) {
    if (state.firestore.data.geoGame_sessions[state.session.id] !== undefined) {
      sessionState =
        state.firestore.data.geoGame_sessions[state.session.id].state;
      players = state.firestore.data.geoGame_sessions[state.session.id].players;
    }
  }
  return {
    sessionCode: state.session.code,
    sessionId: state.session.id,
    players: players,
    sessionState: sessionState,
    games: state.game.games,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createSession: () => dispatch(sessionActions.createSession()),
    postStartGame: (sessionId, gameId, players) =>
      dispatch(sessionActions.startGame(sessionId, gameId, players)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (props.sessionId !== "" && props.sessionId !== null) {
      return [{ collection: "geoGame_sessions", doc: props.sessionId }];
    } else {
      return [];
    }
  })
)(CreateGame);

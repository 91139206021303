import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import rotatingEarth from "../media/rotatingEarth.mp4";
import GameBtn from "../components/GameBtn";
import VideoBkg from "../components/VideoBkg";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "@reduxjs/toolkit";
import { getPlayersSortedByTotalScore } from "../functions/gameFunctions";

const GameOver = ({ session, players, game }) => {
  const navigate = useNavigate();
  const params = useParams();
  const goToLobby = () => {
    navigate("/");
  };

  useEffect(() => {
    if ([null, undefined, ""].includes(params.sessionId)) {
      navigate(`/error`);
    }
    if (params.sessionId !== session.id) {
      if (params.userId) {
        navigate(`/reconnect/${params.sessionId}/${params.userId}`);
        return;
      }
      navigate(`/reconnect/${params.sessionId}`);
    }
  }, [params, session.id, navigate]);

  return (
    <Fragment>
      <VideoBkg src={rotatingEarth} playbackRate={0.8} />
      <div className="game-over-overlay-bkg" />
      <div className="game-over-container">
        <div className="game-over-results-container">
          <div className="game-over-podium-container">
            <div className="game-over-podium-stand-container-2">
              <div className="game-over-podium-name-display">
                {players[1] &&
                  (players[1].name.length > 15
                    ? players[1].name.slice(0, 15) + "..."
                    : players[1].name)}{" "}
              </div>
              <div className="game-over-podium-stand card-shadow">🥈</div>
            </div>
            <div className="game-over-podium-stand-container-1">
              <div className="game-over-podium-name-display">
                {players[0] &&
                  (players[0].name.length > 15
                    ? players[0].name.slice(0, 15) + "..."
                    : players[0].name)}
              </div>
              <div className="game-over-podium-stand card-shadow">🥇</div>
            </div>
            <div className="game-over-podium-stand-container-3">
              <div className="game-over-podium-name-display">
                {players[2] &&
                  (players[2].name.length > 15
                    ? players[2].name.slice(0, 15) + "..."
                    : players[2].name)}
              </div>
              <div className="game-over-podium-stand card-shadow">🥉</div>
            </div>
          </div>
          <div className="game-over-bottom-container">
            <div className="game-over-player-list-container">
              <ul>
                {players.slice(3).map((player) => (
                  <li>{player.name}</li>
                ))}
              </ul>
            </div>
            <div className="game-over-footer">
              <GameBtn
                className="gg-lobby-btn"
                onClick={() => goToLobby}
                title="Bye bye"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  let players = [];
  let session = {};
  let game = {};
  let sessionId = null;
  if (state.user.session.id !== null) {
    sessionId = state.user.session.id;
  } else {
    sessionId = state.session.id;
  }

  if (
    state.firestore.data.geoGame_sessions !== undefined &&
    sessionId !== null
  ) {
    let sessionPlayers =
      state.firestore.data.geoGame_sessions[sessionId].players;
    if (state.firestore.data.geoGame_players !== undefined) {
      sessionPlayers.forEach((player) => {
        players.push({
          ...state.firestore.data.geoGame_players[player.id],
          id: player.id,
        });
      });
    }
    session = state.firestore.data.geoGame_sessions[sessionId];
    if (session.game !== undefined && session.game !== "") {
      game = state.game.games.filter((game) => game.id === session.game)[0];
    }
  }

  players = getPlayersSortedByTotalScore(players);

  return {
    session: { ...session, id: sessionId },
    players: players,
    game: game,
  };
};

//export default connect(mapStateToProps)(GameOver);

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    let arr = [];
    if (
      props.session.id !== "" &&
      props.session.id !== null &&
      props.session.id !== undefined
    ) {
      arr.push({ collection: "geoGame_sessions", doc: props.session.id });
      arr.push({
        collection: "geoGame_players",
        where: ["session", "==", props.session.id],
      });
    }
    return arr;
  })
)(GameOver);

import React from "react";
const Test = () => {
  return (
    <div
      style={{
        backgroundColor: "#fff",
        display: "relative",
        height: "100vh",
        width: "100vw",
      }}
    ></div>
  );
};

export default Test;

import * as fs from "firebase/firestore";
import { db } from "../../config/fbConfigV9";
import gameStateType from "../../model/gameStateTypes";
import {
  computeDistance,
  getCountryOfCoordinates,
} from "../../functions/mapFunctions";
import countryBorders from "../../data/countryBorders.json";
import OnlineUser from "../../model/OnlineUser";
import UserRoundGuess from "../../model/UserRoundGuess";
export const JOIN_SESSION = "JOIN_SESSION";
export const MAKE_GUESS = "MAKE_GUESS";
export const RECONNECT = "MAKE_GUESS";
export const SET_CURRENT_GUESS = "SET_CURRENT_GUESS";

const colors = [
  "#3A709D",
  "#3A9D3E",
  "#939D3A",
  "#9D3A3A",
  "#3A9D82",
  "#9D3A6E",
  "#5D3A9D",
];

export const reconnect = (sessionId, playerId) => async (dispatch) => {
  try {
    let res = null;
    const docRefSession = fs.doc(db, "geoGame_sessions", sessionId);
    const docSnaphotSession = await fs.getDoc(docRefSession);

    if (docSnaphotSession._document === null) {
      throw Error("Could not find game session");
    }

    const docRefPlayer = fs.doc(db, "geoGame_players", playerId);
    const docSnaphotPlayer = await fs.getDoc(docRefPlayer);

    if (docSnaphotPlayer._document === null) {
      throw Error("Could not find player session");
    }

    if (docSnaphotSession.id !== docSnaphotPlayer.data().session) {
      throw Error("Game session not matching player session");
    } else {
      res = {
        sessionId: docSnaphotSession.id,
        userId: docSnaphotPlayer.id,
      };
    }

    dispatch({
      type: RECONNECT,
      payload: {
        player: {
          name: docSnaphotPlayer.data().name,
          id: docSnaphotPlayer.id,
        },
        session: {
          id: docSnaphotSession.id,
          code: docSnaphotSession.data().code,
        },
      },
    });
    return res;
  } catch (err) {
    console.error(err.message);
    throw Error(err.message);
  }
};

export const setCurrentGuess = (coordinates) => async (dispatch) => {
  try {
    dispatch({
      type: SET_CURRENT_GUESS,
      payload: {
        guess: coordinates,
      },
    });
  } catch (err) {
    console.error(err.message);
    throw Error(err.message);
  }
};

export const makeGuess =
  (
    guessCoordinates,
    actualCoordinates,
    actualLocation,
    roundNumber,
    proximityByBorder,
    playerId
  ) =>
  async (dispatch, getState) => {
    try {
      const savedGuessCoordinates = getState().user.currentGuess;
      if (
        savedGuessCoordinates.lat !== null &&
        savedGuessCoordinates.lng !== null
      ) {
        guessCoordinates = savedGuessCoordinates;
      }
      actualLocation =
        actualLocation !== undefined ? actualLocation.toLowerCase() : "";
      let score = Math.round(
        computeDistance(guessCoordinates, actualCoordinates)
      );
      let roundCoordinates = actualCoordinates;

      let guessCountry = await getCountryOfCoordinates(
        guessCoordinates.lat,
        guessCoordinates.lng
      );
      guessCountry = guessCountry !== undefined ? guessCountry : guessCountry;

      if (actualLocation === guessCountry) {
        score = 0;
      } else if (
        proximityByBorder !== undefined &&
        proximityByBorder != null &&
        proximityByBorder
      ) {
        let countryObj = countryBorders[actualLocation];

        if (countryObj !== undefined && countryObj.length > 0) {
          let countryBorderCoordinates = [...countryObj];
          if (
            countryBorderCoordinates !== undefined &&
            countryBorderCoordinates.length > 0
          ) {
            let firstCoordinates = countryBorderCoordinates.pop();
            score = Math.round(
              computeDistance(guessCoordinates, firstCoordinates)
            );
            roundCoordinates = firstCoordinates;

            countryBorderCoordinates.forEach((countryCoordinates) => {
              let newScore = computeDistance(
                guessCoordinates,
                countryCoordinates
              );

              if (newScore < score) {
                score = Math.round(newScore);
                roundCoordinates = countryCoordinates;
              }
            });
          }
        }
      }

      const roundObj = new UserRoundGuess(
        roundNumber,
        score,
        new fs.GeoPoint(guessCoordinates.lat, guessCoordinates.lng),
        new fs.GeoPoint(roundCoordinates.lat, roundCoordinates.lng),
        guessCountry
      );
      const playerRef = fs.doc(db, "geoGame_players", playerId);

      await fs.updateDoc(playerRef, {
        rounds: fs.arrayUnion({ ...roundObj }),
      });
    } catch (err) {
      console.error(err.message);
      throw Error(err.message);
    }
  };

export const joinSession = (code, name) => async (dispatch) => {
  try {
    const q = fs.query(
      fs.collection(db, "geoGame_sessions"),
      fs.where("code", "==", code)
    );
    const querySnapshot = await fs.getDocs(q);

    let session = null;
    querySnapshot.forEach((doc) => {
      session = {
        id: doc.id,
        data: doc.data(),
      };
    });

    if (session != null) {
      if (session.data.state === gameStateType.gameOver) {
        throw Error("This session is no longer active");
      }

      let numPlayersJoined = session.data.players.length;
      let playerColor = colors[numPlayersJoined % colors.length];

      const onlineUser = new OnlineUser(
        name,
        session.id,
        playerColor,
        [],
        fs.Timestamp.now(),
        fs.Timestamp.fromDate(new Date(Date.now() + 120 * 60 * 1000))
      );

      const docRefPlayer = await fs.addDoc(
        fs.collection(db, "geoGame_players"),
        {
          ...onlineUser,
        }
      );

      const sessionRef = fs.doc(db, "geoGame_sessions", session.id);

      await fs.updateDoc(sessionRef, {
        players: fs.arrayUnion({ name: name, id: docRefPlayer.id }),
      });

      if (sessionRef === undefined || docRefPlayer === undefined) {
        throw Error("Could not join session");
      }

      dispatch({
        type: JOIN_SESSION,
        payload: {
          player: {
            name: name,
            id: docRefPlayer.id,
          },
          session: {
            id: session.id,
            code: session.data.code,
          },
        },
      });
      return { sessionId: session.id, userId: docRefPlayer.id };
    } else {
      throw Error("Could not find session");
    }
  } catch (err) {
    console.error(err.message);
    throw Error(err.message);
  }
};

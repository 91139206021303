import React from "react";
import { Route, Routes } from "react-router-dom";

import GameLobby from "../pages/GameLobby";
import Game from "../pages/Game";
import GameBoard from "../pages/GameBoard";
import Error from "../pages/Error";
import GameOver from "../pages/GameOver";
import Reconnect from "../pages/Reconnect";
import ReverseGeoCode from "../pages/ReverseGeoCode";
import Test from "../pages/Test";

import { LayoutContextProvider } from "./LayoutContext";

const AppRoutes = () => {
  return (
    <>
      <LayoutContextProvider>
        <Routes>
          <Route path="/" element={<GameLobby />} />
          <Route path="/join/:gameCode" element={<GameLobby />} />
          <Route path="/game/:sessionId/:userId" element={<Game />} />
          <Route path="/reconnect/:sessionId/:userId" element={<Reconnect />} />
          <Route path="/reconnect/:sessionId/" element={<Reconnect />} />
          <Route path="/gameboard/:sessionId" element={<GameBoard />} />
          <Route path="/gameover/:sessionId" element={<GameOver />} />
          <Route path="/gameover/:sessionId/:userId" element={<GameOver />} />
          <Route path="/error" element={<Error />} />
          <Route path="*" element={<Error />} />
          <Route path="/reversegeocode" element={<ReverseGeoCode />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      </LayoutContextProvider>
    </>
  );
};

export default AppRoutes;

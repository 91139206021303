import * as fs from "firebase/firestore";
import { db } from "../../config/fbConfigV9";
import gameStateType from "../../model/gameStateTypes";
import GameSession from "../../model/GameSession";
export const SET_SESSION = "SET_SESSION";
export const REMOVE_PLAYER = "REMOVE_PLAYER";
export const START_GAME = "START_GAME";
export const RESET_SESSION = "RESET_GAME";

export const reconnect = (sessionId) => async (dispatch) => {
  try {
    let res = null;
    const docRefSession = fs.doc(db, "geoGame_sessions", sessionId);
    const docSnaphotSession = await fs.getDoc(docRefSession);

    if (docSnaphotSession._document === null) {
      throw Error("Could not find session");
    } else {
      res = docSnaphotSession.id;
    }

    // if during round at disconnect, restart round
    if (docSnaphotSession.data().state === gameStateType.duringRound) {
      const sessionRef = fs.doc(db, "geoGame_sessions", docSnaphotSession.id);

      await fs.updateDoc(sessionRef, {
        state: gameStateType.beforeRound,
      });
    }

    dispatch({
      type: SET_SESSION,
      payload: {
        code: docSnaphotSession.data().code,
        id: docSnaphotSession.id,
      },
    });
    return res;
  } catch (err) {
    console.error(err.message);
    throw Error(err.message);
  }
};

export const createSession = () => async (dispatch) => {
  try {
    const code = createCode(6);
    const sessionObj = new GameSession(
      code,
      "",
      gameStateType.inLobby,
      [],
      0,
      fs.Timestamp.now(),
      fs.Timestamp.fromDate(new Date(Date.now() + 120 * 60 * 1000))
    );

    const docRef = await fs.addDoc(fs.collection(db, "geoGame_sessions"), {
      ...sessionObj,
    });
    if (docRef.id !== undefined) {
      dispatch({
        type: SET_SESSION,
        payload: {
          code: code,
          id: docRef.id,
        },
      });
    } else {
      throw Error("Could not create game session");
    }
  } catch (err) {
    console.error(err.message);
    throw Error(err.message);
  }
};

export const startGame = (sessionId, gameId, players) => async (dispatch) => {
  try {
    const sessionRef = fs.doc(db, "geoGame_sessions", sessionId);

    await fs.updateDoc(sessionRef, {
      game: gameId,
      state: gameStateType.loadingGame,
    });

    dispatch({
      type: START_GAME,
      payload: { gameId: gameId, players: players },
    });
  } catch (err) {
    console.error(err.message);
  }
};

export const nextRound = (sessionId) => async (dispatch) => {
  try {
    const sessionRef = fs.doc(db, "geoGame_sessions", sessionId);

    await fs.updateDoc(sessionRef, {
      currentRound: fs.increment(1),
      state: gameStateType.beforeRound,
    });
  } catch (err) {
    console.error(err.message);
  }
};

export const startRound = (sessionId) => async (dispatch) => {
  try {
    const sessionRef = fs.doc(db, "geoGame_sessions", sessionId);

    await fs.updateDoc(sessionRef, {
      state: gameStateType.duringRound,
    });
  } catch (err) {
    console.error(err.message);
  }
};

export const endRound = (sessionId) => async (dispatch) => {
  try {
    const sessionRef = fs.doc(db, "geoGame_sessions", sessionId);

    await fs.updateDoc(sessionRef, {
      state: gameStateType.afterRound,
    });
  } catch (err) {
    console.error(err.message);
  }
};

export const endGame = (sessionId) => async (dispatch) => {
  try {
    const sessionRef = fs.doc(db, "geoGame_sessions", sessionId);

    await fs.updateDoc(sessionRef, {
      state: gameStateType.gameOver,
    });
  } catch (err) {
    console.error(err.message);
  }
};

export const resetSession = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_SESSION,
      payload: {},
    });
  } catch (err) {
    console.error(err.message);
  }
};

export const removePlayerFromSession = (playerId) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_PLAYER,
      payload: {},
    });
  } catch (err) {
    console.error(err.message);
  }
};

const createCode = (size) => {
  var code = "";
  var letterNumberSet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < size; i++)
    code += letterNumberSet.charAt(
      Math.floor(Math.random() * letterNumberSet.length)
    );

  return code.toUpperCase();
};
